<template>
  <div class="privacy">
    <section class="text-center bg-image-6">
      <img src="@/assets/images/banner-ctp.png" alt="banner" />
    </section>

    <!-- Presentation-->
    <section
      style="margin-top: -25px"
      class="section novi-bg novi-bg-img section-xl bg-default text-center background-privacidad"
      id="section-see-features"
    >
      <div class="container">
        <div class="row justify-content-lg-center">
          <div class="col-lg-10 col-xl-8">
            <h2>
              <b>{{ $t('lang.privacy.title') }}</b>
            </h2>

            <div style="text-align: left; margin-top: 50px">
              <h5 style="font-weight: 600">{{ $t('lang.privacy.subtitle_1') }}</h5>
              <p v-html="$t('lang.privacy.description_1')"></p>
              <p v-html="$t('lang.privacy.description_2')"></p>
              <p v-html="$t('lang.privacy.description_3')"></p>
            </div>

            <div style="text-align: left; margin-top: 50px">
              <h5 style="font-weight: 600">{{ $t('lang.privacy.subtitle_2') }}</h5>
              <p v-html="$t('lang.privacy.description_4')"></p>
              <p v-html="$t('lang.privacy.description_5')"></p>
              <p v-html="$t('lang.privacy.description_6')"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'Privacy',
  data: () => ({})
});
</script>
